import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { ThemeProvider } from "styled-components"
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles"
import theme from "../styles/theme/index.js"
import GlobalCss from "../styles/theme/global"
import Hero from "../components/Hero"
import Article from "../components/Article"
import ArticlePagination from "../components/ArticlePagination"
import SEO from "../components/SEO"

const ArticlePage = ({ data, location, pageContext }) => {
  const { contentfulBlogArticle } = data
  const { prev, next } = pageContext
  const {
    title,
    author,
    image,
    dateOfPublication,
    synopsis: { synopsis },
  } = contentfulBlogArticle
  const canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0)
    }
  }, [location])
  const metadata = {
    seoTitle: title,
    seoDescription: { seoDescription: synopsis },
    seoImage: image,
  }
  const heroData = {
    ctaBlock: {
      overline: null,
      title,
      titleMarkup: "h1",
      description: null,
      cta: null,
      author: {
        author,
        dateOfPublication,
      },
    },
    media: {
      media: image,
      polariod: true,
    },
  }
  return (
    <>
      <SEO data={metadata} canonicalUrl={canonicalUrl} article />
      <StylesProvider>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <GlobalCss />
            <Layout hasHero={true}>
              <Hero data={heroData} />
              <Article data={contentfulBlogArticle} />
              <ArticlePagination prev={prev} next={next} />
            </Layout>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </>
  )
}

export const query = graphql`
  query article($id: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulBlogArticle(id: { eq: $id }) {
      id
      title
      dateOfPublication(formatString: "DD MMMM YYYY")
      author {
        name
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          svg {
            content
          }
        }
        description {
          description
        }
        occupation
      }
      tags {
        title
        slug
      }
      image {
        file {
          url
        }
        gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
      }
      synopsis {
        synopsis
      }
      articleBody {
        raw
        references {
          ... on ContentfulAsset {
            # contentful_id is required to resolve the references
            __typename
            contentful_id
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
          ... on ContentfulVideoFeatured {
            __typename
            contentful_id
            id
            title
            featuredVideo
          }
          ... on ContentfulMedia {
            __typename
            contentful_id
            id
            media {
              gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
            }
            maxWidth
            polariod
          }
        }
      }
    }
  }
`

export default ArticlePage
