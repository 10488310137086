import React from "react"
import styled from "styled-components"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import ResourceCard from "./ResourceCard"
import Wrapper from "./layout/Wrapper"
import { Typography } from "@material-ui/core"

export default function ArticlePagination({ prev, next }) {
  if (!prev && !next) return
  return (
    <Box pb={10}>
      <Wrapper small>
        <CentredTitle mb={2}>
          <Typography variant="h3" color="textPrimary">
            Related Posts
          </Typography>
        </CentredTitle>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {prev && <ResourceCard data={prev} />}
          </Grid>
          <Grid item xs={12} md={6}>
            {next && <ResourceCard data={next} />}
          </Grid>
        </Grid>
      </Wrapper>
    </Box>
  )
}

const CentredTitle = styled(Box)`
  text-align: center;
`
