import React from "react"
import styled from "styled-components"
import Wrapper from "./layout/Wrapper"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import richTextOptions from "./RichText"
import Divider from "./Divider"
import { AuthorWithDescription } from "./Author"
import ButtonCta from "./Button"
import { renderRichText } from "gatsby-source-contentful/rich-text"

export default function Article({ data }) {
  const { author, tags, articleBody } = data
  return (
    <Box py={10}>
      <Wrapper small>
        <ArticleCard>
          {tags && <TagHeader tags={tags} />}
          <Box py={2}>
            {articleBody &&
              renderRichText(
                articleBody,
                richTextOptions("textPrimary", "regular")
              )}
          </Box>
          <ButtonWrapper mt={4} mb={2}>
            <ButtonCta
              maxWidth
              variant="contained"
              colour="black"
              href="/pricing"
              gtagevent="get-started-blog"
            >
              {`Try Expend`}
            </ButtonCta>
          </ButtonWrapper>
          <ButtonWrapper mt={2} mb={4}>
            <ButtonCta
              maxWidth
              variant="contained"
              colour="secondary"
              href="/book-a-call"
              gtagevent="book-a-demo-blog"
            >
              {`Book a call`}
            </ButtonCta>
          </ButtonWrapper>
          {tags && <TagHeader tags={tags} />}
          {author && <AuthorWithDescription author={author} />}
        </ArticleCard>
      </Wrapper>
    </Box>
  )
}

const TagHeader = ({ tags }) => {
  return (
    <TagsContainer py={4}>
      {tags.map((tag, index) => (
        <Tag key={index} href={`/resources/blog/${tag.slug}`}>
          {tag.title}
        </Tag>
      ))}
      <Divider />
    </TagsContainer>
  )
}

const ButtonWrapper = styled(Box)`
  ${({ theme }) => `
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    `}
`

const ArticleCard = styled(Box)`
  ${({ theme }) => `
        width: 100%;
        box-shadow: ${theme.shadows[2]};
        border-radius: ${theme.shape.borderRadius};
        padding: ${theme.spacing(4)}px;
    `}
`

const TagsContainer = styled(Box)`
  ${({ theme }) => `
        display: flex;
        position: relative;
        flex-wrap: wrap;
        & > a:not(:last-child) {
            margin-right: ${theme.spacing(2)}px;
        }
    `}
`

const Tag = styled(Button)`
  ${({ theme }) => `
        background: ${theme.palette.lights.primary[100]};
        border-radius: 50px;
        padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
        color: ${theme.palette.primary.main};
        box-shadow: ${theme.shadows[6]};
        transition: all 0.2s ease-in-out;
        &:hover {
            transform: translateY(-1px);
            box-shadow: ${theme.shadows[7]};
            background: ${theme.palette.lights.primary[200]};
        }
    `}
`
