import React from "react"
import styled from "styled-components"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Button from "./Button"
import Grid from "./Grid"
import { navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function ResourceCardContainer({ data, tag, size }) {
  const { title, slug, synopsis, image } = data
  return (
    <Card onClick={() => navigate(`/resources/blog/${slug}`)}>
      <Grid
        container
        spacing={0}
        alignItems="flex-start"
        direction={size === "xs" ? "row-reverse" : "row"}
      >
        {image && (
          <Grid
            item
            xs={12}
            md={
              (size === "lg" && 5) ||
              (size === "md" && 12) ||
              (size === "sm" && 12) ||
              (size === "xs" && 5)
            }
            size={size}
          >
            <CardImageContainer size={size}>
              <Image image={getImage(image)} alt={title} />
              <Overlay />
            </CardImageContainer>
          </Grid>
        )}
        <CardContentGrid
          item
          xs={12}
          md={
            (size === "lg" && 7) ||
            (size === "md" && 12) ||
            (size === "sm" && 12) ||
            (size === "xs" && 7)
          }
          size={size}
        >
          <Box px={1}>
            <Box mb={1}>
              <Typography variant="overline" color="primary">
                {tag}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography
                variant={
                  (size === "lg" && "h2") ||
                  (size === "md" && "h4") ||
                  (size === "sm" && "h6") ||
                  (size === "xs" && "h6") ||
                  (!size && "h4")
                }
                color="textPrimary"
              >
                {title}
              </Typography>
            </Box>
            {size !== "sm" && size !== "xs" && synopsis && (
              <Box mb={2}>
                <Typography variant="body1" color="textSecondary">
                  {synopsis.synopsis}
                </Typography>
              </Box>
            )}
            <Button
              variant="text"
              colour="secondary"
              href={`/resources/blog/${slug}`}
            >
              Read more
            </Button>
          </Box>
        </CardContentGrid>
      </Grid>
    </Card>
  )
}

const Card = styled(Box)`
  ${({ theme }) => `
        padding: ${theme.spacing(2)}px;
        box-shadow: ${theme.shadows[1]};
        border-radius: ${theme.shape.borderRadius};
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        height: 100%;
        &:hover {
            box-shadow: ${theme.shadows[3]};
        }
    `}
`

const Overlay = styled(Box)`
  ${({ theme }) => `
        border-radius: ${theme.shape.borderRadius};
        width: 100%;
        height: 100%;
        background: rgba(165, 235, 245, 0.15);
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.2s ease-in-out;
        ${Card}:hover & {
            background: rgba(165, 235, 245, 0.35);
        }
    `}
`

const CardImageContainer = styled.div`
  ${({ theme }) => `
        width: 100%;
        border-radius: ${theme.shape.borderRadius};
        position: relative;
        overflow: hidden;
    `}
  ${({ size, theme }) =>
    size === "lg"
      ? `
        height: 260px;
        ${theme.breakpoints.down("md")} {
            margin-bottom: ${theme.spacing(2)}px;
        }
    `
      : `
        height: 200px;
        margin-bottom: ${theme.spacing(2)}px;
    `}
`

const Image = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  // Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
  & > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
    font-family: "object-fit: cover !important; object-position: 0% 0% !important;"; // needed for IE9+ polyfill
  }
`

const CardContentGrid = styled(Grid)`
  ${({ theme, size }) =>
    size === "lg" &&
    `
        ${theme.breakpoints.up("md")} {
            padding-left: ${theme.spacing(2)}px;
        }
    `}
`
